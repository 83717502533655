import React from "react"
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet"

export default function Legal() {

    return (

        <>

        <Helmet>
            <meta charSet="utf-8" />
            <title>Legal Mentions | Skillmeter</title>
        </Helmet>
                
        <Header />

        <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl ">
              Legal Mentions
            </h2>
          </div>
  
          <div className="max-w-5xl mx-auto mt-10 sm:mt-20">
            <div className="flow-root">
            <div className="-my-8 divide-y divide-gray-200">
                <div className="py-8">
                    <p className="text-2xl font-bold text-gray-900 ">
                    Skillmeter.com is owned and operated by:
                    </p>

                    <p className="mt-8 text-lg font-normal text-gray-600 ">
                    ALGORITHMA SAS<br />
                RCS Registration Number: 882 871 775<br />
                Address: 2 rue de la Libération, 94130 Nogent sur Marne, France
                <br /><br />
                Email: support@skillmeter.com <br />
                {/*Déclaration CNIL n°1665330*/}
                    </p>
                </div>


              </div>
            </div>
          </div>
        </div>
      </section>
  
        <Footer />
      </>
    )
}